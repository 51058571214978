@import "variables";
@import "icon.mixin";
@import "label.mixin.scss";

.container {
  margin-bottom: $spacing-t7;
  :global(.#{$ns}-form-group) {
    margin: 0px;
  }
}

.border {
  border-bottom: 1px solid $color-border;
  padding-bottom: $spacing-t7;
  margin-bottom: $spacing-t7;
}

.heading {
  font-size: $font-size-t5;
  line-height: $line-height-t5;
  font-weight: $font-weight-bold;
  color: $color-text-default;
  text-transform: uppercase;
}

.actionStyle {
  font-size: $font-size-t5;
  line-height: $line-height-t5;
  font-weight: $font-weight-bold;
  color: $color-text-primary;
  cursor: pointer;
}

.clear {
  @extend .actionStyle;
  margin-left: $spacing-t8;
}

.showMore {
  @extend .actionStyle;
  margin-top: $spacing-t8;
}

.icon {
  cursor: pointer;
  @include icon-style-primary;
}

.section {
  padding-top: $spacing-t7;
  user-select: all !important;
}

.selectedList {
  padding-top: $spacing-t7;
}

.label {
  display: flex;
  align-items: center;
  user-select: all !important;
  @include label;
}

.toDate {
  margin-bottom: 0 !important;
}

.filterIcon {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: $color-button-bg-default !important;
  padding: $spacing-t9 $spacing-t8;
  min-height: 32px !important;
  border-radius: $border-radius-medium !important;

  :global(.fill-color) {
    color: $color-icon-default;
    fill: $color-icon-default;
  }

  &:hover {
    :global(.fill-color) {
      color: $color-icon-primary;
      fill: $color-icon-primary;
    }
  }
}
